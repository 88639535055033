import React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import logo from '../assets/logo.svg'
import * as vars from '../styles/export'

const Wrapper = styled.div`
  background: ${vars.colors.teal};
  margin: 0 auto 1.45rem auto;
  text-align: center;
`
const StyledH1 = styled.h1`
  margin: 0 auto;
  max-width: 960px;
  padding: 1rem;

  a {
    color: ${vars.colors.white};
    text-decoration: none;
  }
`
const Logo = styled.div`
  display: table;
  margin: 0 auto 10px auto;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
  height: 60px;
  background-size: contain;
`

const Header = () => (
  <Wrapper>
    <StyledH1>
      <Link to='/'><Logo/>Signature Generator</Link>
    </StyledH1>
  </Wrapper>
)

export default Header
