import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Header from '../components/header'
import '../styles/index.css'

export default ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}

    render={data => (
      <div>
        <Helmet title={data.site.siteMetadata.title}
          meta={[{ name: 'description', content: 'Klipr Signature Generator' }]}
        />
        <Header siteTitle={data.site.siteMetadata.title}/>
        {children}
      </div>
    )}
  />
)
